/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */



(function($) {

  var body = $("html, body");
  var barTop;
  var iniCap;

  function igualaAlturas(_itm){

    $(_itm).css('height','initial');
    var altMax=0;
    $(_itm).each(function(i){

       console.log($(this).parent().attr('class')+" >>> "+$(this).height() );

      altMax = Math.max(altMax, $(this).height() );
    });

      console.log(altMax);

    $(_itm).height(altMax);

  }

  function igualaAltoAncho(_itm){
    $(_itm).css('height','initial');

    $(_itm).each(function(i){
     // altMAx = Math.max(altMax, $(this).height() );
      $(this).height($(this).width());
    });


  }


  function scrolleo(){
    barTop = window.pageYOffset;

    if(barTop > 95){

        $('.banner').addClass('nav_fix');
        $('.alt_fix').height($('.banner').outerHeight(true) *2 );

        $('.alt_fix').removeClass('d_none');



    }else{

      $('.banner').removeClass('nav_fix');
      $('.alt_fix').addClass('d_none');

    }


    console.log(barTop);

    var porPos =50+ 50 * ( barTop/392 )+"%";
    console.log(porPos);
   /* iniCap */


    $('.backCoverCenter').css({
      'background-position':'50% '+ porPos
    });


  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        $('.bot_buscador').click(function(event){
          event.preventDefault();

          if($(window).width()<540){
            $('.tit_blog').fadeToggle('fast', function(){
              $('.buscador').fadeToggle('normal');
              $('.ico_lupa , .ico_cierra').toggleClass('d_none');
            });
          }else{

          $('.buscador').fadeToggle('normal');
          $('.ico_lupa , .ico_cierra').toggleClass('d_none');

          }
         //




        });


          jQuery('#aceptarcookies').on('click', function(ev){



              ev.preventDefault();
              jQuery('.cdp-cookies-boton-cerrar').trigger('click');


          });



        //DROPDOWN SUB MENU
        $('.dropdown-toggle').on('click', function(ev){
          $(this).next().slideToggle(300);
        });


        $('.logo_sube').on("click", function(event){
          event.preventDefault();
          body.stop().animate({scrollTop:0}, '500', 'swing', function() {
            // alert("Finished animating");
          });

        });

       // $('.maxWDer').css({'width':$('.container').width() *0.75});

       // alert($('.maxWDer').width());


       if($('.img_pastel').length>0){


        $('.img_pastel').css('margin-top', $('.desc_servicio h4').outerHeight(true) );

       }

       if(jQuery('.backCoverCenter').length>0){

       // alert(jQuery('.backCoverCenter'));

          iniCap = (jQuery('.backCoverCenter').css('background-position'));

          iniCap = jQuery('.backCoverCenter').offset().top + jQuery('.backCoverCenter').height();


       }

      //console.log(iniCap);


    /*  $('.sub_menu').hover(function(){

        $('.mega_menu').toggleClass('d_none');

      }, function(){

        $('.mega_menu').toggleClass('d_none');

      });*/


    $('.sub_menu > a').on('click',function(event){

      event.preventDefault();

      $('.mega_menu').slideToggle(400);
      $('.mega_menu').toggleClass('d_none');
      $('.wrap').toggleClass('opa');


    });


    $('.mega_menu').on('mouseleave', function(event){

      $('.mega_menu').slideToggle(400);
      $('.mega_menu').toggleClass('d_none');
      $('.wrap').toggleClass('opa');
    });



    //GO FORMULARIO

   var body = $("html, body");



    $('.cont_contacto a').click(function(event){

      event.preventDefault();
      var goTo = $(this).attr('href');
      goTo = $(goTo).offset().top;
      body.stop().animate({scrollTop:goTo}, '500', 'swing', function() {

      });

    });


var sobreMenu=false;





        //$('li.navbar-nav').not('.sub_menu').css('background-color','red');

        $('.mega_menu').toggleClass('d_none');

        igualaAlturas($('.cat_menu_prin') );

        $('.mega_menu').toggleClass('d_none');



        $('.desc_servicio a:not(.noBlank)').attr('target','_blank');



            $('.cerrar_cookie').click(function(event){
              event.preventDefault();
              $('.alerta_cookie').slideUp(400);
            });



            if($('.open_next')){

              $('.open_next').click(function(event){

                event.preventDefault();

                $(this).next().slideToggle();
                $(this).toggleClass('abierto');

              })

            }


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


      }
    },

    'archive':{
      init:function(){

        igualaAltoAncho($('.link_serv'));
        igualaAlturas($('.desc_subcat'));

        //alert("ARCHIVE!");

        if($('.row_pest').length>0 ){

          //$('.cont_sub_sub_cat').first().addClass("pesta_open");;

          $('.bot_pesta').on('click' , function(event){
            event.preventDefault();
            var pest = $(this).attr('href');
            $('.pesta_open').toggleClass("pesta_open");
            $(pest).toggleClass("pesta_open");

            $('.pesta_activa').toggleClass("pesta_activa");
            $(this).toggleClass("pesta_activa");
          });
          //bot_pesta


          $('.bot_pesta').first().addClass("pesta_activa");;

          $('.cont_sub_sub_cat').last().removeClass("pesta_open");;

        }

      },
      finalize:function(){

      }
    },

    'search':{
      init:function(){

        igualaAltoAncho($('.link_serv'));

      },
      finalize:function(){

      }
    },

    'blog':{
      init:function(){

        igualaAltoAncho($('.link_serv'));

      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        igualaAlturas('.home_blog .titular_blog');


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


      }
    },
    // About us page, note the change from about-us to about_us.
    'single_servicio': {
      init: function() {

        igualaAlturas('.tit_desta');


      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      $(window).scroll(scrolleo);


      $(window).load(function(){

       // igualaAlturas($('.item_online_1 .eq_alt'));
        igualaAlturas($('.item_online_2 .eq_alt'));

      //  jQuery('body').css('padding-bottom', (jQuery('.footer').outerHeight() + 10) +"px" ) //PARA EL FOOTER


        igualaAlturas('.home_blog .titular_blog');

        igualaAlturas('.item_pg_blog .conj_alt');



        jQuery('#otros_servicios').on('click', function(ev){
          ev.preventDefault();
           //jQuery(ev.currentTarget).next().slideToggle(400)
           jQuery(ev.currentTarget).next().toggleClass('d-flex');
        });







      }); //load


    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery(window).on('resize', function(){

  console.log("RESIZE");
  // jQuery('body').css('padding-bottom', (jQuery('.footer').outerHeight()) +"px" )

  if(cont_street){
      iframe = cont_street.querySelectorAll('iframe');
      iframe = iframe[0];
      anchIframe = cont_street.getBoundingClientRect();
      anchIframe = anchIframe.width;

      console.log('anchIframe');
      console.log(anchIframe);

      propIframe = parseInt(iframe.getAttribute('width')) / parseInt(iframe.getAttribute('height'));

      console.log("propIframe");
      console.log(propIframe);
      iframe.style.height = (anchIframe/propIframe) +"px";
    //  window.dispatchEvent(new Event('resize'));
  }




})

var divFrase
var divFraseDesta

var arrInFrases=[];
  var intFrase;
  var contF;
function hagoCarruselFrases(){

  //Hay frases

  console.log(arrFrases);

  divFrase  = jQuery('.frases');
   divFraseDesta = jQuery('.frase_desta');

  // if(jQuery(divFrase).size()>0){


  // var arrFrases = ['Personal<br>cualificado',
  //                   'Atención<br>personalizada',
  //                   'Cuidado<br>de los<br>detalles',
  //                   'Tratamiento<br>confindencial',
  //                   'Espacio<br>seguro'
  //                 ];

  for(var a=0; a<arrFrases.length; a++){

    var tmpString = arrFrases[a];
    // console.log(tmpString);

    arrFrases[a] = arrFrases[a].replace('<br />', '<br>');
    arrFrases[a] =  arrFrases[a].replace('<br/>', '<br>');

    var  inTmpArrBr = arrFrases[a].split("<br>");
    var  resArr=new Array();

    for(var b=0; b<inTmpArrBr.length;b++){

      var tmpRR = inTmpArrBr[b].split('');
      resArr =  resArr.concat(tmpRR);
      resArr.push('<br>');

    //  console.log('tmpRR');
    //  console.log(tmpRR);
    //  console.log('resArr');
    //  console.log(resArr);

    }

    arrInFrases.push(resArr);

    // console.log(inTmpArrBr);

    // console.log('inTmpArrBr');
    // console.log(inTmpArrBr);

  } ; // for arrFrases

  //arrFrases.map(function(arr){
  // console.log(arrInFrases);

    contF=0;

    console.log(arrInFrases);

    divFraseDesta.html(arrInFrases[contF]);
    contF++;

    intFrase =  setInterval(tiempoFrase, 2000);

}

function tiempoFrase(){

  jQuery(divFraseDesta).html('');

  escribe(arrInFrases[contF]);

 // divFraseDesta.html(arrInFrases[contF]);

  contF++;

  if(contF>arrInFrases.length-1 ){
    contF = 0;
  }


}

var tiempoMeca = 0;
var tiempoMecaFin;
var intMeca ;
var strConstruyo="" ;
var selString ;
function escribe(_st){

 // console.log("ESCRIBE");
  //console.log(_st);
  jQuery(divFraseDesta).html('');


  selString=_st;

 // console.log('escribo');
 // console.log(selString);
  // console.log('selString');
  // console.log(selString);
  strConstruyo="";
  tiempoMecaFin = _st.length;
  intMeca = setInterval(letraLetra, 40);
  tiempoMeca =0;

  clearInterval(intFrase);

}

function letraLetra(){



      if(tiempoMeca>=tiempoMecaFin){
        tiempoMeca =0;
        clearInterval(intMeca);

        intFrase =  setInterval(tiempoFrase, 5000);

      }else{
      //console.log(selString[tiempoMeca] );
      strConstruyo+=selString[tiempoMeca];
      // console.log('strConstruyo' );
      // console.log(strConstruyo );
      jQuery(divFraseDesta).html(strConstruyo);

          tiempoMeca++;

      }


}

var cont_street;
var iframe;
var anchIframe;
var propIframe;

window.onload = function(){


      cont_street = document.getElementById('cont_street');


      console.log('cont_street');

      console.log(cont_street);

    if(cont_street){
        iframe = cont_street.querySelectorAll('iframe');
        iframe = iframe[0];
        anchIframe = iframe.getBoundingClientRect();
        anchIframe = anchIframe.width;
        propIframe = parseInt(iframe.getAttribute('width')) / parseInt(iframe.getAttribute('height'));
        iframe.style.height = (anchIframe/propIframe) +"px";
        window.dispatchEvent(new Event('resize'));
    }



    console.log('/cont_street');


  var miBody = document.getElementsByTagName('body');
  miBody = miBody[0];


  console.log('miBody');

  console.log(miBody);

  console.log(miBody.classList);

    if(  miBody.classList.contains('page-template-template-metodo') ){

      console.log('HAGO FRASES');

      //   if(  typeof(arrFrases) !== 'undefined' || arrFrases === null ){
           hagoCarruselFrases();
      //    }


    }

    console.log('/miBody');




    cont_blobs = document.getElementsByClassName('img_blob');

    Array.prototype.forEach.call(cont_blobs, function(blob){
      var miTmpBlob = blob;
      var ranDeg = Math.random()*360;
      miTmpBlob.style.transform = "rotate(" + ranDeg  + "deg)"
    })

    initSmooth();
}

//MOUSEWHEEL

function initSmooth(){
  new SmoothScroll(document,120,12)
}

function SmoothScroll(target, speed, smooth) {
  if (target === document)
    target = (document.scrollingElement
              || document.documentElement
              || document.body.parentNode
              || document.body) // cross browser support for document scrolling

  var moving = false
  var pos = target.scrollTop
  var frame = target === document.body
              && document.documentElement
              ? document.documentElement
              : target // safari is the new IE

  target.addEventListener('mousewheel', scrolled, { passive: false })
  target.addEventListener('DOMMouseScroll', scrolled, { passive: false })

  function scrolled(e) {
    e.preventDefault(); // disable default scrolling

    var delta = normalizeWheelDelta(e)

    pos += -delta * speed
    pos = Math.max(0, Math.min(pos, target.scrollHeight - frame.clientHeight)) // limit scrolling

    if (!moving) update()
  }

  function normalizeWheelDelta(e){
    if(e.detail){
      if(e.wheelDelta)
        return e.wheelDelta/e.detail/40 * (e.detail>0 ? 1 : -1) // Opera
      else
        return -e.detail/3 // Firefox
    }else
      return e.wheelDelta/120 // IE,Safari,Chrome
  }

  function update() {
    moving = true

    var delta = (pos - target.scrollTop) / smooth

    target.scrollTop += delta

    if (Math.abs(delta) > 0.5)
      requestFrame(update)
    else
      moving = false
  }

  var requestFrame = function() { // requestAnimationFrame cross browser
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.oRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(func) {
        window.setTimeout(func, 1000 / 50);
      }
    );
  }()
}
